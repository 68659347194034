/* You can add global styles to this file, and also import other style files */
@layer base {
  html,
  body {
    @apply bg-[#15151B] font-['Poppins'];
  }

  .text-outline {
    -webkit-text-stroke: 1px #fff;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  @apply mx-auto max-w-[1280px] px-6;
}

.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
